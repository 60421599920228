import { navigate } from 'gatsby-link';
import React, { useState } from 'react'
import CircleButton from "../components/CircleButton";
import Layout from "../components/Layout";
import Keyboard from '../components/Keyboard';
import ReCAPTCHA from "react-google-recaptcha";


const CommentForm = () => {
  const [input, setInput] = useState("");

  const handleInputChange = (event) => {
    setInput(event.target.value);
  }

  return (
    <Layout>
      <div
        className={`popup-text white`}
        style={{ background: "black" }}
      >
        <Keyboard setInput={setInput} currentValue={input} />
        <CircleButton
          top={3} left={3} type={"close"} color={"white"}
          onClick={() => navigate("/")}
        />
        <div className='content'>
          <div className="comment-form">
            <form name="contact" method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              action="/?form-submitted"
              // data-netlify-recaptcha="true"
            >
              <input type="hidden" name="form-name" value="contact" />
              <input type="hidden" name="bot-field" />
              <div>
                <textarea type="text" name="comment"
                  value={input}
                  onChange={handleInputChange}
                />
              </div>
              {/* <ReCAPTCHA sitekey="6LfK8pkdAAAAAGwafOUu1-RhiuO2gOBwH6KfVS0g" theme="dark" /> */}
              {/* <div data-netlify-recaptcha="true"></div> */}
              <div>
                <button type="submit">Absenden</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
};

export default CommentForm;
